import React, { useEffect } from 'react';
import Airtable from 'airtable';
import Dashboard from './Dashboard';
import { handleTokenAndEmail } from './tokenAndEmail'; // Import the function

const base = new Airtable({ apiKey: 'patmR9viVXmX3PJAw.71eebdadd0da2fb125650748e7e07b574b6d1a90643cb2f0baade97c33cf4c86' }).base('appmyBUPyd7QLQcwp');

const Home = ({ userRole, userName , onLogout }) => {
  useEffect(() => {
    const updatePublishField = async () => {
      try {
        const records = await base('Forms').select().all();
        const now = new Date();

        for (const record of records) {
          const PublishOn = record.fields.PublishOn;
          const Publish = record.fields.Publish;
          const recordId = record.id;
          const form_name = record.fields.FormName;

          if (PublishOn) {
            const publishOnDate = new Date(PublishOn);

            // Check if the publishOnDate is passed and the Publish field is not already true
            if (publishOnDate <= now && !Publish) {
              await base('Forms').update(recordId, {
                Publish: true
              });
              console.log(`Updated record ${recordId}: set publish to true`);

              // Call the function to handle token creation and email sending
              await handleTokenAndEmail(recordId);
            }
          }
        }
      } catch (error) {
        console.error('Error updating records:', error);
      }
    };

    const intervalId = setInterval(updatePublishField, 10000); // Check every 10 seconds
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <h2>Welcome Home <br /> {userName }</h2>
      <Dashboard userRole={userRole} userName={userName} />
      <button onClick={onLogout}>Logout</button>
    </div>
  );
};

export default Home;
