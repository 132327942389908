// src/components/Dashboard.js
import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Airtable from 'airtable';
import { useUser } from '../utils/UserContext';
import '../styles/Dashboard.css';


const localizer = momentLocalizer(moment);
const base = new Airtable({ apiKey: 'patmR9viVXmX3PJAw.71eebdadd0da2fb125650748e7e07b574b6d1a90643cb2f0baade97c33cf4c86' }).base('appmyBUPyd7QLQcwp');

const Dashboard = () => {
  const { username, userRole, batch, loading } = useUser();
  const [events, setEvents] = useState([]);
  const [answersData, setAnswersData] = useState([]);

  useEffect(() => {
    if (loading) return; // Skip fetching if still loading

    const fetchData = async () => {
      try {
        const usersRecords = await base('Users').select().all();
        const users = usersRecords.map(record => ({
          username: record.fields.UserName,
          batch: record.fields.Batch,
        }));

        const answersRecords = await base('Answers').select().all();
        const answers = answersRecords.map(record => ({
          username: record.fields.UserName,
          formName: record.fields.FormName,
          result: record.fields.Result || 'Not Submitted',
          submittedDate: record.fields.SubmittedDate,
          batch: record.fields.Batch,
        }));

        const formsRecords = await base('Forms').select().all();
        const forms = formsRecords.reduce((acc, record) => {
          acc[record.fields.FormName] = {
            publishOn: record.fields.PublishOn,
            batch: record.fields.Batch,
          };
          return acc;
        }, {});

        const combinedData = Object.keys(forms).map(formName => {
          const form = forms[formName];
          const answersForForm = answers.filter(a => a.formName === formName);
          const studentsForBatch = users.filter(u => form.batch.includes(u.batch));

          return studentsForBatch.map(student => {
            const answer = answersForForm.find(a => a.username === student.username);
            const missed = !answer || !answer.submittedDate;

            return {
              student: student.username,
              exam: formName,
              batch: student.batch,
              publishOn: form.publishOn,
              submittedDate: answer ? answer.submittedDate : 'Not Submitted',
              result: answer ? answer.result : 'Not Declared',
              missed,
            };
          });
        }).flat();

        const filteredData = userRole === 'Student' && username
          ? combinedData.filter(data => data.student === username)
          : combinedData;

        setAnswersData(filteredData);

        const calendarEvents = filteredData.map(data => ({
          title: data.exam,
          start: new Date(data.publishOn),
          end: new Date(data.publishOn),
          style: { color: data.missed ? 'red' : 'green' },
        }));

        setEvents(calendarEvents);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [loading, userRole, batch, username]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!username) {
    return <div>No user data available</div>;
  }

  return (
    <div className="dashboard-container">
      <div className="calendar-container">
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          eventPropGetter={(event) => ({
            style: event.style
          })}
        />
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Student</th>
              <th>Exam</th>
              <th>Batch</th>
              <th>Result</th>
              <th>Published Date</th>
              <th>Submitted Date</th>
            </tr>
          </thead>
          <tbody>
            {answersData.map((data, index) => (
              <tr key={index} style={{ color: data.missed ? 'red' : 'black' }}>
                <td>{data.student}</td>
                <td>{data.exam}</td>
                <td>{data.batch}</td>
                <td>{data.result}</td>
                <td>{data.publishOn ? new Date(data.publishOn).toLocaleDateString() : 'N/A'}</td>
                <td>{data.submittedDate !== 'Not Submitted' ? new Date(data.submittedDate).toLocaleDateString() : 'Not Submitted'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Dashboard;
