// src/utils/UserContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import Airtable from 'airtable';

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState({
    username: '',
    userRole: '',
    college: '',
    batch: '',
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const base = new Airtable({ apiKey: 'patmR9viVXmX3PJAw.71eebdadd0da2fb125650748e7e07b574b6d1a90643cb2f0baade97c33cf4c86' }).base('appmyBUPyd7QLQcwp');
    const username = localStorage.getItem('username');

    if (username) {
      base('Users').select({
        filterByFormula: `{UserName} = '${username}'`,
      }).firstPage().then((records) => {
        if (records.length > 0) {
          const user = records[0].fields;
          setUserData({
            username: user.UserName || '',
            userRole: user.UserRole || '',
            college: user.College || '',
            batch: user.Batch || '',
          });
        }
      }).catch(err => console.error('Error fetching user details:', err))
      .finally(() => setLoading(false)); // Set loading to false once data is fetched
    } else {
      setLoading(false); // If username is not set, stop loading
    }
  }, []);

  return (
    <UserContext.Provider value={{ ...userData, loading }}>
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => useContext(UserContext);

export { UserProvider, useUser };
