import Airtable from 'airtable';
import emailjs from '@emailjs/browser';
import crypto from 'crypto-browserify'; // Import crypto-browserify

// Configure Airtable with your API key and base ID
const base = new Airtable({ apiKey: 'patmR9viVXmX3PJAw.71eebdadd0da2fb125650748e7e07b574b6d1a90643cb2f0baade97c33cf4c86' }).base('appmyBUPyd7QLQcwp');

// Function to generate a random 5-character access token
const generateAccessToken = () => {
  return crypto.randomBytes(3).toString('hex');
};

// Function to update record with access token
const updateRecordWithToken = async (recordId, token) => {
  try {
    await base('Forms').update(recordId, {
      FormToken: token
    });
    console.log(`Updated record ${recordId}: set token`);
  } catch (error) {
    console.error('Error updating record with token:', error);
  }
};

// Function to send an email using EmailJS
const sendEmail = async (to, subject, htmlContent, formtoken, formname) => {
  const serviceID = 'service_kg7nzwh';
  const templateID = 'template_epitz5q';
  const publicKey = 'IPKU9lULv-zLEloIi';  // Use public key for client-side

  const templateParams = {
    to_email: to,
    subject: subject,
    access_token: formtoken,
    form_name: formname,
    html_content: htmlContent
  };

  try {
    await emailjs.send(serviceID, templateID, templateParams, publicKey);
    console.log(`Email sent to ${to}`);
  } catch (error) {
    console.error('Error sending email:', error);
  }
};

// Function to handle token creation and email sending
export const handleTokenAndEmail = async (recordId) => {
  try {
    const accessToken = generateAccessToken();

    // Update the record with the access token
    await updateRecordWithToken(recordId, accessToken);

    // Fetch the form record to get the token, name, and college
    const formRecord = await base('Forms').find(recordId);
    const formtoken = formRecord.get('FormToken');
    const formname = formRecord.get('FormName');
    const formCollege = formRecord.get('College');

    // Fetch users with the role of 'admin' or 'teacher'
    const fetchUsers = async () => {
      try {
        console.log('Fetching users with filter: ', "OR({UserRole} = 'admin', {UserName} = 'Kshitij')");
        const records = await base('Users').select({
          filterByFormula: "OR({UserRole} = 'admin', {UserName} = 'Kshitij')"
        }).all();
        console.log('Fetched users: ', records);
        return records;
      } catch (error) {
        console.error('Error fetching users:', error);
        return []; // Return an empty array in case of an error
      }
    };
    
    const users = await fetchUsers(); // Await the result of fetchUsers()

    // Email content template
    const emailTemplate = `
    <p>Hello,</p>

    <p>An Exam on College Examiner for <strong>${formname}</strong> has been published. Your access token is: <strong>${formtoken}</strong></p>

    <p>Please share this access token with students so that they can access the exam</p>
    <p>Thank you!</p>`;

    // Send email to each user
    for (const user of users) {
      const email = user.get('Email');
      const userCollege = user.get('College');

      // Check if the colleges match
      if (userCollege === formCollege) {
        await sendEmail(email, 'New Publication Access', emailTemplate, formtoken, formname);
      }
    }

    console.log('Emails sent to all admin and teacher users.');
  } catch (error) {
    console.error('Error handling token and email:', error);
  }
};
